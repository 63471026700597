/* eslint-disable quotes */
import type { ColorFont, ColorSchema, Palette, Theme } from "themes/interfaces";
import { BaseBorder, BaseColor, BaseFont, BaseMargin } from "themes/base";
import { Enums } from "utils";

export const Dark: Theme = {
  id: Enums.EnumTheme.Dark,
  font: BaseFont,
  color: BaseColor,
  palette: {
    theme: "#303030",
    border: "#404040",
    shadow: "0 3px 10px 0 #D0D0D0",
    input: { color: "#F5F5F5", accent: "#DBDBDB" } as ColorSchema,
    font: { color: "#ffffff", accent: "#c3c3c3" } as ColorFont,
    background: { color: "#202020", accent: "#151515", textAccent: "#ffffff" } as ColorSchema
  } as Palette,
  border: BaseBorder,
  margin: BaseMargin,
};
