import { Apis } from "utils";

export interface IGithubData {
  all(): Promise<any>;
}

export class GithubData implements IGithubData {

  async all() {
    try {
      return await Apis.github().get("users/sergio-righi/repos");
    } catch (err) {
      return null;
    }
  }
}