import "assets/scss/components/item_project.scss";

import React from "react";
import { Box, Stack } from "@mui/material";
import { Custom, Icon } from "components";
import { useApp, useTheme } from "contexts";
import { Thumb } from "assets/images";
import { ProjectType } from "types";

type Props = {
  item: ProjectType;
};

export const Project = (props: Props) => {
  const { theme } = useTheme();
  const { locale, t } = useApp();

  return (
    <Box
      component="a"
      target="_blank"
      href={props.item.href}
      className="rp-project-item"
      aria-label={props.item.name}
      borderRadius={theme.border.radius}
    >
      <Box className="thumb">
        <Box className="shadow">
          <Box className="title">
            <Custom.Typography color={theme.color.white}>
              {props.item.name}
            </Custom.Typography>
          </Box>
          <Stack direction="row" className="icon" spacing={theme.margin.md}>
            {props.item.stack.map((item: string) => {
              return (
                <Icon.Dev key={item} name={item} color={theme.color.white} />
              );
            })}
          </Stack>
          <Stack className="description" spacing={theme.margin.sm}>
            <Custom.Typography
              variant="h4"
              color={theme.color.white}
              weight={theme.font.semiBold}
            >
              {props.item.briefdescription[locale.toLowerCase()]}
            </Custom.Typography>
            <Custom.Typography color={theme.color.white} variant="h5">
              {t.label.status} : {props.item.status[locale.toLowerCase()]}
            </Custom.Typography>
          </Stack>
        </Box>
        <Box component="img" src={Thumb} alt={props.item.name} />
      </Box>
    </Box>
  );
};
