import React from "react";
import { Box, Stack, SxProps } from "@mui/material";
import { LazyCompany } from "assets/images";
import { useApp, useTheme } from "contexts";
import { Custom } from "components";

type Props = {
  sx: SxProps;
  onClick: () => void;
};

export const Logo = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Stack
      sx={props.sx}
      direction="row"
      alignItems="center"
      className="c-pointer"
      onClick={props.onClick}
      spacing={theme.margin.sm}
    >
      <Box display="flex" width={48} mr={theme.margin.xs}>
        <LazyCompany />
      </Box>
      <Custom.Typography
        noWrap
        variant="h4"
        mr={theme.margin.md}
        weight={theme.font.medium}
        color={theme.color.accent.text}
      >
        {t.navbar.title}
      </Custom.Typography>
    </Stack>
  );
};
