import React from "react";
import { Box } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { ActivityType } from "types";
import { ExperienceData } from "assets/data";
import { Custom, Item } from "components";

type Props = {};

export const Experience = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Box component="section" id="experience" className="section-experience">
      <Box className="section-header">
        <Custom.Typography variant="h3">{t.title.experience}</Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="section-containter">
          <Box className="content">
            {ExperienceData.map((item: ActivityType, i: number) => {
              return <Item.Experience key={i} item={item} />;
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
