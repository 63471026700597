import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ServiceProvider, useTheme } from "contexts";
import { ThemeProvider } from "@mui/material/styles";
import { Themes } from "utils";
import { Common } from "components";

export const DefaultLayout = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={Themes.defaultTheme(theme)}>
      <ServiceProvider>
        <Box component="main" bgcolor={theme.palette.background.color}>
          <Common.Navbar />
          <Outlet />
          <Common.Footer />
        </Box>
      </ServiceProvider>
    </ThemeProvider>
  );
};
