import "assets/scss/components/item_experience.scss";

import React from "react";
import { Box, Stack } from "@mui/material";
import { Common, Custom, Icon } from "components";
import { useApp, useTheme } from "contexts";
import { HDate } from "helpers";
import { ActivityType } from "types";
import { Constants } from "utils";

type Props = {
  item: ActivityType;
};

export const Experience = (props: Props) => {
  const { locale, t } = useApp();
  const { theme } = useTheme();

  return (
    <Box className="rp-experience-item">
      <Stack spacing={theme.margin.sm}>
        <Stack
          className="header"
          px={theme.margin.md}
          py={theme.margin.sm}
          spacing={theme.margin.sm}
          borderRadius={theme.border.radius}
          borderColor={theme.palette.font.color}
        >
          <Custom.Typography variant="h4" color={theme.palette.font.color}>
            {props.item.at} / {props.item.as[locale.toLowerCase()]}
          </Custom.Typography>
          <Custom.Typography variant="h5" color={theme.palette.font.accent}>
            {HDate.formattedDate(
              HDate.toDate(props.item.from),
              locale,
              Constants.DATE_FORMAT.YEAR_MONTH
            )}{" "}
            -{" "}
            {props.item.to
              ? HDate.formattedDate(
                  HDate.toDate(props.item.to),
                  locale,
                  Constants.DATE_FORMAT.YEAR_MONTH
                )
              : t.label.present}
            , {props.item.in[locale.toLowerCase()]}{" "}
            {props.item.type
              ? `(${props.item.type[locale.toLowerCase()]})`
              : ""}
          </Custom.Typography>
        </Stack>
        <Stack
          className="content"
          px={theme.margin.md}
          spacing={theme.margin.md}
        >
          <Custom.Typography variant="h5" lineHeight={1.65}>
            {props.item.briefdescription[locale.toLowerCase()]}
          </Custom.Typography>
          <Stack direction="row" spacing={theme.margin.sm}>
            {props.item.stack.map((item: string) => {
              return (
                <Icon.Dev
                  key={item}
                  name={item}
                  size="large"
                  color={theme.palette.font.color}
                />
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
