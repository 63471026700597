import "assets/scss/components/item_education.scss";

import React from "react";
import { Box, Stack } from "@mui/material";
import { Common, Custom } from "components";
import { useApp, useTheme } from "contexts";
import { ActivityType } from "types";
import { HDate } from "helpers";
import { Constants } from "utils";

type Props = {
  item: ActivityType;
};

export const Education = (props: Props) => {
  const { locale } = useApp();
  const { theme } = useTheme();

  return (
    <Box
      className="item"
      pl={theme.margin.md}
      mb={theme.margin.xl}
      borderColor={theme.palette.font.color}
    >
      <Stack spacing={theme.margin.md}>
        <Stack className="header" spacing={theme.margin.xs}>
          <Custom.Typography variant="h4">
            {props.item.at} /{" "}
            {props.item.type ? props.item.type[locale.toLowerCase()] : ""}
          </Custom.Typography>
          <Custom.Typography variant="h5" color={theme.palette.font.accent}>
            {HDate.formattedDate(
              HDate.toDate(props.item.from),
              locale,
              Constants.DATE_FORMAT.YEAR_MONTH
            )}{" "}
            -{" "}
            {HDate.formattedDate(
              HDate.toDate(props.item.to ?? ""),
              locale,
              Constants.DATE_FORMAT.YEAR_MONTH
            )}
            , {props.item.in[locale.toLowerCase()]} -{" "}
            {props.item.as[locale.toLowerCase()]}
          </Custom.Typography>
        </Stack>
        <Box className="content" pb={theme.margin.md}>
          <Custom.Typography variant="h5" lineHeight={1.65}>
            {props.item.briefdescription[locale.toLowerCase()]}
          </Custom.Typography>
        </Box>
      </Stack>
    </Box>
  );
};
