import { Box, Stack } from "@mui/material";
import { Custom } from "components";
import { useApp, useTheme } from "contexts";
import { Github, Instagram, LinkedIn } from "assets/icons";

import { MailRounded } from "@mui/icons-material";

type Props = {
  color?: string;
  size?: "small" | "medium" | "large" | undefined;
};

export const Social = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  const iconSize =
    props.size === "small"
      ? 16
      : props.size === "medium"
      ? 24
      : props.size === "large"
      ? 32
      : 32;

  const iconColor = props.color ?? theme.palette.font.color;

  return (
    <Stack direction="row">
      <Box
        component="a"
        target="_blank"
        href="https://github.com/sergio-righi/"
        aria-label="GitHub"
      >
        <Custom.IconButton>
          <Github width={iconSize} height={iconSize} fill={iconColor} />
        </Custom.IconButton>
      </Box>
      <Box
        component="a"
        href="https://www.linkedin.com/in/sergio-righi-740316182/"
        target="_blank"
        aria-label="LinkedIn"
      >
        <Custom.IconButton>
          <LinkedIn width={iconSize} height={iconSize} fill={iconColor} />
        </Custom.IconButton>
      </Box>
      <Box
        component="a"
        href="https://www.instagram.com/sergiohrighi/"
        target="_blank"
        aria-label="Instagram"
      >
        <Custom.IconButton>
          <Instagram width={iconSize} height={iconSize} fill={iconColor} />
        </Custom.IconButton>
      </Box>
      <Box
        component="a"
        target="_blank"
        aria-label={t.aria.label.email}
        href="mailto:sergiohrighi@gmail.com"
      >
        <Custom.IconButton textColor={iconColor}>
          <MailRounded sx={{ fontSize: iconSize }} />
        </Custom.IconButton>
      </Box>
    </Stack>
  );
};
