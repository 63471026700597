import React from "react";
import { Box, Grid } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Custom, Item } from "components";
import { ProjectType } from "types";
import { ProjectData } from "assets/data";

type Props = {};

export const Project = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const projects = ProjectData.filter((item: ProjectType) => item.active);

  return projects.length > 0 ? (
    <Box component="section" id="project" className="section-project">
      <Box className="section-header">
        <Custom.Typography variant="h3">{t.title.project}</Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="project-container">
          <Box className="content">
            <Grid container spacing={theme.margin.sm}>
              {projects.map((item: ProjectType, i: number) => {
                return (
                  <Grid item key={i} xs={12} sm={6} md={4}>
                    <Item.Project item={item} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};
