import React from "react";
import { Box, Grid } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Custom, Item } from "components";
import { TechStackData } from "assets/data";
import { TechStackType } from "types";

type Props = {};

export const TechStack = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Box component="section" id="tech_stack" className="section-tech-stack">
      <Box className="section-header">
        <Custom.Typography variant="h3">{t.title.tech_stack}</Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="tech-stack-container">
          <Box className="content">
            <Grid container spacing={theme.margin.md}>
              {TechStackData.map((item: TechStackType, i: number) => {
                return (
                  <Grid item key={i} xs={12} sm={6} md={4}>
                    <Item.TechStack item={item} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
