import React from "react";
import { Box, Stack } from "@mui/material";
import { Common, Custom } from "components";
import { useApp, useTheme } from "contexts";
import { HDate, HString } from "helpers";
import { Constants } from "utils";

type Props = {};

export const Footer = (props: Props) => {
  const { locale, t } = useApp();
  const { theme } = useTheme();

  return (
    <Box bgcolor={theme.palette.background.accent} height={60}>
      <Stack
        height={1}
        direction="row"
        alignItems="center"
        px={theme.margin.xl}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={theme.margin.xs}>
          <Custom.Typography
            as="span"
            color={theme.palette.background.textAccent}
          >
            {t.message.made_with}
          </Custom.Typography>
          <Box component="span">❤️</Box>
          <Custom.Typography
            as="span"
            color={theme.palette.background.textAccent}
          >
            {HString.interpolate(t.date.last_updated, {
              date: HDate.formattedDate(
                HDate.toDate("2023-08-15"),
                locale,
                Constants.DATE_FORMAT.YEAR_MONTH
              ),
            })}
          </Custom.Typography>
        </Stack>
        <Box>
          <Common.Social
            size="medium"
            color={theme.palette.background.textAccent}
          />
        </Box>
      </Stack>
    </Box>
  );
};
