import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Common, Custom } from "components";
import { User } from "assets/images";

type Props = {};

export const Intro = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Box component="section" id="intro" className="section-intro">
      <Box className="section-content">
        <Box className="intro-container" mt={theme.margin.xxl}>
          <Box className="content">
            <Grid container alignItems="center" spacing={theme.margin.md}>
              <Grid item xs={12} md={6}>
                <Box p={{ md: theme.margin.xl }}>
                  <Box
                    width={1}
                    src={User}
                    component="img"
                    alt={t.alt.profile}
                    borderRadius={theme.border.radius}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={theme.margin.sm}>
                  <Custom.Typography variant="h2" lineHeight={1.65}>
                    {t.intro.header}
                  </Custom.Typography>
                  <Custom.Typography
                    variant="h4"
                    lineHeight={2}
                    weight={theme.font.normal}
                  >
                    {t.intro.work}
                  </Custom.Typography>
                  <Custom.Typography
                    variant="h4"
                    lineHeight={2}
                    weight={theme.font.normal}
                  >
                    {t.intro.life}
                  </Custom.Typography>
                  <Custom.Typography
                    variant="h4"
                    lineHeight={2}
                    weight={theme.font.normal}
                  >
                    {t.intro.headline}
                  </Custom.Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Common.Social />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
