import "assets/scss/components/item_tech_stack.scss";

import React from "react";
import { Box, Stack } from "@mui/material";
import { Common, Custom, Icon } from "components";
import { useApp, useTheme } from "contexts";
import { TechStackType } from "types";

type Props = {
  item: TechStackType;
};

export const TechStack = (props: Props) => {
  const { locale } = useApp();
  const { theme } = useTheme();

  return (
    <Box p={theme.margin.sm} className="rp-tech-stack-item">
      <Stack spacing={theme.margin.sm}>
        <Box className="header">
          <Custom.Typography variant="h5">
            {props.item.title[locale.toLowerCase()]}
          </Custom.Typography>
        </Box>
        <Box className="content">
          <Stack direction="row" spacing={theme.margin.sm}>
            {props.item.stack.map((item: string) => (
              <Icon.Dev
                key={item}
                name={item}
                size="large"
                color={theme.palette.font.color}
              />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
