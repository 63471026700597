import "assets/scss/pages/home.scss";

import { Common } from "components";

type Props = {};

export const Home = (props: Props) => {
  return (
    <>
      <Common.Intro />
      <Common.Summary />
      <Common.TechStack />
      <Common.Project />
      <Common.Repo />
      <Common.Experience />
      <Common.Education />
    </>
  );
};
