import React from "react";
import { Box } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Custom } from "components";

type Props = {};

export const Summary = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Box component="section" id="summary" className="section-summary">
      <Box className="section-header">
        <Custom.Typography variant="h3">{t.title.summary}</Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="summary-container">
          <Box className="content">
            <Box component="ul">
              {t.summary.list.map((item: string, i: number) => (
                <Box component="li" key={i} color={theme.palette.font.color}>
                  <Custom.Typography variant="h5" lineHeight={2}>
                    {item}
                  </Custom.Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
