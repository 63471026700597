import { Box } from "@mui/material";

type Props = {
  name?: string;
  color: string;
  colored?: boolean;
  language?: string;
  size?: "small" | "medium" | "large" | undefined;
};

export const Dev = (props: Props) => {
  const languageIcon =
    props.language === "JavaScript"
      ? "javascript-plain"
      : props.language === "TypeScript"
      ? "typescript-plain"
      : props.language === "Vue"
      ? "vuejs-plain"
      : props.language === "C#"
      ? "csharp-plain"
      : "github-original";

  const className = props.name
    ? `devicon-${props.name} ${props.colored ? "colored" : ""}`
    : props.language
    ? `devicon-${languageIcon} ${props.colored ? "colored" : ""}`
    : "";

  const fontSize =
    props.size === "small"
      ? 16
      : props.size === "medium"
      ? 24
      : props.size === "large"
      ? 32
      : undefined;

  return (
    <Box
      component="i"
      color={props.color}
      fontSize={fontSize}
      className={className}
    />
  );
};

Dev.defaultProps = {
  colored: false,
};
