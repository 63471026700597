import React, { createContext, useContext } from "react";
import { Constants, Store } from "utils";

interface ProvidedValueType {
  user: any;
  setUser: (user: any) => void;
  t: any;
  locale: string;
  locales: string[];
  setLocale: (language: string) => void;
}

const initialState = {
  locale: "EN",
  translations: Constants.LOCALE_STRINGS[Constants.REGIONS.EN],
  locales: Object.keys(Constants.REGIONS) as string[],
  user: Store.sessionStorage.get() as any,
};

export const AppContext = createContext<ProvidedValueType>({
  user: initialState.user,
  setUser: () => {},
  t: initialState.translations,
  locale: initialState.locale,
  locales: initialState.locales,
  setLocale: () => {},
});

interface Props {
  initLocale: string;
  children?: React.ReactNode;
}

export const AppProvider = React.memo<Props>(
  ({ initLocale, children }: Props) => {
    const [user, setUser] = React.useState<any>(initialState.user);

    const [locale, setLocale] = React.useState<string>(initLocale);

    const setUserCallback = React.useCallback((newUser: any | null) => {
      setUser((currentUser: any | null) => {
        const user = {
          ...currentUser,
          ...newUser,
        } as any;
        if (newUser === null) {
          Store.sessionStorage.clear();
        } else {
          Store.sessionStorage.set({ ...user });
        }
        return Store.sessionStorage.get();
      });
    }, []);

    const MemoizedValue = React.useMemo(() => {
      const value: ProvidedValueType = {
        locale,
        t: Constants.LOCALE_STRINGS[locale],
        setLocale,
        locales: initialState.locales,
        user,
        setUser: setUserCallback,
      };
      return value;
    }, [locale, user, setUserCallback]);

    return (
      <AppContext.Provider value={MemoizedValue}>
        {children}
      </AppContext.Provider>
    );
  }
);

export const useApp = () => useContext(AppContext);
