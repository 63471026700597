import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useApp, useService, useTheme } from "contexts";
import { Custom, Item } from "components";
import { RepoType } from "types";

type Props = {};

export const Repo = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const { github } = useService();
  const [repositories, setRepositories] = useState<RepoType[]>([]);

  useEffect(() => {
    fetchRepositories();
  }, []);

  async function fetchRepositories() {
    setRepositories(await github.all());
  }

  return (
    <Box component="section" id="repo" className="section-repo">
      <Box className="section-header">
        <Custom.Typography variant="h3">{t.title.repo}</Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="repo-container">
          <Box className="content">
            <Grid container spacing={theme.margin.md}>
              {repositories
                .filter((item: RepoType) => !item.archived)
                .map((item: RepoType, i: number) => {
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={i}>
                      <Item.Repo item={item} />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
