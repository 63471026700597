import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Menu,
  Container,
  MenuItem,
  Stack,
} from "@mui/material";
import { DarkModeRounded, Menu as MenuIcon } from "@mui/icons-material";
import { Common, Custom } from "components";
import { useApp, useTheme } from "contexts";
import { LazyCompany } from "assets/images";

type Props = {};

type SectionItem = {
  id: string;
  title: string;
};

export const Navbar = (props: Props) => {
  const { theme, toggleTheme } = useTheme();
  const { locale, locales, setLocale, t } = useApp();
  const appBarRef = React.useRef<HTMLElement | null>(null);
  const [localeMenu, setLocaleMenu] = useState<HTMLElement | null>(null);
  const [navigationMenu, setNavigationMenu] = useState<HTMLElement | null>(
    null
  );

  const pages: SectionItem[] = [
    { id: "summary", title: t.title.summary },
    { id: "tech_stack", title: t.title.tech_stack },
    { id: "project", title: t.title.project },
    { id: "repo", title: t.title.repo },
    { id: "experience", title: t.title.experience },
    { id: "education", title: t.title.education },
  ];

  function handleThemeClick() {
    toggleTheme();
  }

  function handleNavigationOpen(event: React.MouseEvent<HTMLElement>) {
    setNavigationMenu(event.currentTarget);
  }

  function handleNavigationClose() {
    setNavigationMenu(null);
  }

  function handleLocaleOpen(event: React.MouseEvent<HTMLElement>) {
    setLocaleMenu(event.currentTarget);
  }

  function handleLocaleClose() {
    setLocaleMenu(null);
  }

  function handleSetLocale(locale: string) {
    setLocale(locale);
    handleLocaleClose();
  }

  function handleScrollTo(id: string) {
    const element = document.getElementById(id);
    if (element) {
      const { clientHeight = 0 } = appBarRef.current as HTMLElement;
      window.scrollTo(0, element.offsetTop - clientHeight);
    }
    handleNavigationClose();
  }

  function handleScrollTop() {
    window.scrollTo(0, 0);
  }

  return (
    <AppBar ref={appBarRef} position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Common.Logo
            onClick={handleScrollTop}
            sx={{
              display: { xs: "none", lg: "flex" },
            }}
          />
          <Box flexGrow={1} display={{ xs: "flex", lg: "none" }}>
            <Custom.IconButton
              size="large"
              onClick={handleNavigationOpen}
              textColor={theme.color.accent.text}
            >
              <MenuIcon />
            </Custom.IconButton>
            <Custom.Menu
              id="navigation-menu"
              anchorEl={navigationMenu}
              onClose={handleNavigationClose}
              sx={{ display: { xs: "block", lg: "none" } }}
            >
              {pages.map((item: SectionItem) => (
                <MenuItem key={item.id} onClick={() => handleScrollTo(item.id)}>
                  <Custom.Typography align="center">
                    {item.title}
                  </Custom.Typography>
                </MenuItem>
              ))}
            </Custom.Menu>
          </Box>{" "}
          <Common.Logo
            onClick={handleScrollTop}
            sx={{
              width: 1,
              justifyContent: "center",
              display: { xs: "flex", lg: "none" },
            }}
          />
          <Stack direction="row" ml="auto">
            <Box display={{ xs: "none", lg: "flex" }}>
              <Stack direction="row" spacing={theme.margin.xs}>
                {pages.map((item: SectionItem) => (
                  <Custom.Button
                    key={item.id}
                    onClick={() => handleScrollTo(item.id)}
                  >
                    {item.title}
                  </Custom.Button>
                ))}
              </Stack>
            </Box>
            <Box flexGrow={1}>
              <Custom.IconButton
                size="medium"
                onClick={handleLocaleOpen}
                textColor={theme.color.accent.text}
              >
                {locale}
              </Custom.IconButton>
              <Custom.Menu
                id="locale-menu"
                anchorEl={localeMenu}
                onClose={handleLocaleClose}
              >
                {locales.map((item: string) => (
                  <MenuItem key={item} onClick={() => handleSetLocale(item)}>
                    <Custom.Typography align="center">{item}</Custom.Typography>
                  </MenuItem>
                ))}
              </Custom.Menu>
            </Box>
          </Stack>
          <Custom.IconButton
            size="large"
            onClick={handleThemeClick}
            textColor={theme.color.accent.text}
          >
            <DarkModeRounded />
          </Custom.IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
