import { GithubData, IGithubData } from "data";
import { RepoType } from "types";

export interface IGithubService {
  all(): Promise<RepoType[]>;
}

export class GithubService implements IGithubService {
  githubData: IGithubData;

  constructor() {
    this.githubData = new GithubData();
  }

  /**
   * get all the respositories of the user
   * @returns 
   */

  async all() {
    const response = await this.githubData.all();
    if (response.data) {
      return response.data;
    }
  }
}