import "assets/scss/components/item_repo.scss";

import React from "react";
import { Box, Stack } from "@mui/material";
import { Common, Custom, Icon } from "components";
import { useApp, useTheme } from "contexts";
import { RepoType } from "types";
import { HDate } from "helpers";

type Props = {
  item: RepoType;
};

export const Repo = (props: Props) => {
  const { theme } = useTheme();
  const { locale, t } = useApp();

  return (
    <Box
      component="a"
      target="_blank"
      className="rp-repo-item"
      href={props.item.html_url}
      aria-label={props.item.name}
    >
      <Stack
        direction="column"
        p={theme.margin.md}
        spacing={theme.margin.xl}
        borderRadius={theme.border.radius}
        bgcolor={theme.color.accent.color}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Custom.Typography variant="h5" color={theme.color.accent.text}>
            {props.item.name}
          </Custom.Typography>
          <Icon.Dev
            size="medium"
            language={props.item.language}
            color={theme.color.accent.text ?? ""}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Custom.Typography as="span" color={theme.color.accent.text}>
            {props.item.description}
          </Custom.Typography>
          <Stack>
            <Custom.Typography
              as="span"
              align="right"
              color={theme.color.accent.text}
            >
              {t.date.last_modified}
            </Custom.Typography>
            <Custom.Typography
              as="span"
              align="right"
              color={theme.color.accent.text}
            >
              {HDate.formattedDate(new Date(props.item.updated_at), locale)}
            </Custom.Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
