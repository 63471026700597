import React from "react";
import { Menu as MUIMenu, SxProps } from "@mui/material";
import { useTheme } from "contexts";

type Props = {
  id: string;
  sx?: SxProps;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  children: React.ReactNode;
};

export const Menu = (props: Props) => {
  const { theme } = useTheme();

  return (
    <MUIMenu
      id={props.id}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: theme.palette.background.color,
        },
        ...props.sx,
      }}
    >
      {props.children}
    </MUIMenu>
  );
};
