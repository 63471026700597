import { ActivityType, EducationType, ProjectType, TechStackType } from "types";

import Education from "assets/data/education.json";
import Experience from "assets/data/experience.json";
import Project from "assets/data/project.json";
import TechStack from "assets/data/tech_stack.json";

export const EducationData = Education as unknown as EducationType;
export const ExperienceData = Experience as ActivityType[];
export const ProjectData = Project as unknown as ProjectType[];
export const TechStackData = TechStack as unknown as TechStackType[];