import React from "react";
import { Box, Stack } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Custom, Item } from "components";
import { EducationData } from "assets/data";
import { ActivityType } from "types";

type Props = {};

export const Education = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Box component="section" id="education" className="section-education">
      <Box className="section-header">
        <Custom.Typography variant="h3">{t.title.education}</Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="education-container">
          {Object.keys(EducationData)
            .reverse()
            .map((item: string) => {
              return (
                <Box key={item} className="rp-education-item">
                  <Stack direction="row">
                    <Box
                      className="header"
                      borderRadius={theme.border.radius}
                      borderColor={theme.palette.font.color}
                    >
                      <Custom.Typography
                        variant="h4"
                        weight={theme.font.normal}
                        color={theme.palette.font.color}
                      >
                        {item}
                      </Custom.Typography>
                    </Box>
                    <Box className="content" pt={theme.margin.md}>
                      {EducationData[item].map(
                        (item: ActivityType, i: number) => {
                          return <Item.Education key={i} item={item} />;
                        }
                      )}
                    </Box>
                  </Stack>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};
